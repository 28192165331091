<template>
 <div class="calculator-container">
    <!-- Левый блок с слайдером -->
    <div class="slider-block__wrapper">
      <div class="slider-block">
        <div class="slider-wrapper">
          <span class="slider-label">{{ label }}</span>
          <div ref="slider" class="slider"></div>
        </div>
      </div>
    </div>

    <!-- Правый блок с выводом значения -->
    <div class="value-block">
      <span class="slider-value">
        <span class="slider-number">{{ numericValue }}</span>
        <span class="slider-unit">{{ unit }}</span>
      </span>
    </div>
  </div>
</template>

<script>
import noUiSlider from "nouislider";
import "nouislider/dist/nouislider.css";

export default {
  props: {
    label: String,
    min: Number,
    max: Number,
    step: Number,
    modelValue: Number,
    unit: String,
  },
  data() {
    return {
    };
  },
  computed: {
    numericValue() {
      return `${this.modelValue.toLocaleString()}`;
    },
  },
  mounted() {
    noUiSlider
      .create(this.$refs.slider, {
        start: this.modelValue,
        range: { min: this.min, max: this.max },
        step: this.step,
        connect: [true, false],
      })
      .on("update", (values) => {
        this.$emit('update:modelValue', Number(values[0]))
      });
  },
};
</script>

<style>
.calculator-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 20px;
  margin-bottom: 5px;

}
.slider-block__wrapper {
  background: #f5f5f5;
  width: 70%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 56px;
  padding: 0px 15px;
}
.noUi-connect {
  background: transparent !important;
}
.noUi-handle:before,
.noUi-handle:after {
  background: transparent !important;
}
.slider-block {
  position: relative;
  flex: 1;
}

.slider-wrapper {
  position: relative;
  width: 100%;
}

.slider-label {
  position: absolute;
  width: 100%;
  text-align: center;
  color: #040404;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.value-block {
  min-width: 80px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  background: #eee;
  padding: 15px 5px 15px 10px;
  border-radius: 56px;
  height: 60px;
}
.slider-number {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 176%;
}
.slider-unit {
  color: #767676;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 176%;
  margin-left: 7px;
}

.noUi-horizontal .noUi-handle{
  width: 38px;
  height: 38px;
  top: -12px;
}
.noUi-target {
  border-radius: 50px;
  border: none;
  box-shadow: none;
  background: transparent;
  width: 100%;
}

.noUi-connect {
  background: #e87d57;
}

.noUi-handle {
  background: #e87d57;
  border-radius: 50%;
  border: none;
  box-shadow: none;
  width: 20px;
  height: 20px;
}
</style>
