<style scoped>
::v-deep(.q-field__native) {
  text-align: center;
}

::v-deep(.q-select .q-field__input) {
  text-align: center;
}

::v-deep(.q-field--standout.q-field--highlighted .q-field__native) {
  text-align: center;
}

label {
  width: 220px;
}

::v-deep(.q-field__native) {
  font-size: 14px !important;
}
</style>
<style>
.unsub-btn {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  border-radius: 10px;
  background: #ed885c;
  border: 1px solid #ed885c;
  padding: 10px 15px;
  text-align: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  margin-top: 30px;
}

.unsub-btn:hover {
  background: transparent;
  color: #ed885c;
}
.error-find {
  text-align: center;
  color: #c10015;
  font-size: 12px;
}
</style>
<template>
  <div class="row column q-pa-md q-gutter-sm">
    <div class="title">Введите ваш номер телефона</div>
    <q-input
      :rules="phoneValidate"
      rounded
      standout
      ref="inputPhone"
      placeholder="Телефон"
      v-model="phone"
      mask="+7 (###) ###-####"
      @focus="handleFocus"
      @click="handleFocus"
    />

    <button class="unsub-btn q-mb-md" @click="next" :disabled="loading">
      {{ loading ? "Поиск..." : "ОТПИСАТЬСЯ" }}
    </button>
    <div v-show="error" class="error-find">
      {{ error }}
    </div>
  </div>
</template>

<script>
import * as Subscription from "@/assets/js/bid/subscription_iframe.js";
import * as Validate from "@/assets/js/validators.js";

export default {
  name: "UnsubForm",

  props: {
    forwardPage: Function,
  },

  data: () => ({
    phone: "",
    error: "",
    loading: false,
  }),

  computed: {
    phoneValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите телефон",
        (val) => Validate.phone(val) || "Введите полностью",
      ];
    },
  },

  methods: {
    handleFocus() {
      if (!this.phone) {
        this.phone = "9"; // Устанавливаем 9, чтобы маска отобразилась
      }
      this.$nextTick(() => {
        const input = this.$refs.inputPhone?.$el?.querySelector("input");
        if (input) {
          requestAnimationFrame(() => {
            input.setSelectionRange(input.value.length, input.value.length);
          });
        }
      });
    },

    async next() {
      if (!this.phone) {
        this.error = "Введите телефон";
        return;
      }

      this.error = "";
      this.loading = true;

      const result = await Subscription.subscriptions(this.phone);

      if (result.error || !result.subscriptions.length) {
        this.error = result.error || "По данному номеру не найдено карт";
        this.loading = false;
        return;
      }

      this.loading = false;
      this.forwardPage(
        Promise.resolve({
          phone: this.phone,
          subscriptions: result.subscriptions,
        })
      );
    },
  },
};
</script>

