<template>
    <iframe height="630" width="100%" scrolling="no" :src="formUrl" style="border:none;"></iframe>
</template>

<script>
export default {
    name: 'SubscriptionBest2Pay',

    props: {
        renderForm: Function,
        renderError: Function,
        redirectCallback: Function,
        formUrl: String
    },

    methods: {
        handleMessage(e) {
            console.log("🚀 ~ message event:", e)
            var key = e.message ? "message" : "data";
            var data = e[key];
            if (e.origin && new URL(e.origin).hostname === new URL(window.location.origin).hostname) {
                this.redirectCallback(data);
            }
        }
    },

    mounted() {
        // Listen to message from child window
        window.addEventListener("message", this.handleMessage);
    },
    beforeUnmount() {
        window.removeEventListener("message", this.handleMessage);
    }
}
</script>
