<style scoped>
.anketa-wrap {
  padding: 0px ;
}
::v-deep(.q-field__native) {
  text-align: center;
}
::v-deep(.q-select .q-field__input) {
  text-align: center;
}
::v-deep(.q-field--standout.q-field--highlighted .q-field__native) {
  text-align: center;
}
::v-deep(.q-field--standout.q-field--highlighted .q-field__input) {
  text-align: center;
}

.params-wrapper {
  max-width: 600px;
  width: 100%;
  border-radius: 20px;
  background: white !important;
  padding: 23px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.params {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
}
.param {
  display: flex;
  align-items: center;
}


.success {
  border-radius: 15px;
  background: linear-gradient(90deg, #0ba360 0%, #3cba92 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 340px;
  width: 100%;
  padding: 15px 25px;
}

.success img {
  margin-left: 10px;
  margin-top: -3px;
}

.card-add__wrapper {
  border-radius: 30px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 30px 35px 30px;
  max-width: 450px;
  margin: 0 auto;
  width: 100%;
}
.card-add {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 300px;
}
.card-add__title {
  color: #000;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 200;
  line-height: 120%;
}
.card-add__form {
  margin-top: 20px;
  width: 100%;
  margin-bottom: 30px;
}
.card-add__form__data{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card-add__form__data .q-field{
  width: 48%;
}
.card-num{
  margin-bottom: 10px !important;
}
.card-button__wrapper{
  border-radius: 12px;
background: #ED885C;
border: 1px solid #ED885C;
padding: 12px 24px;
transition: all 0.3s ease-in-out;
}
.card-button__wrapper:hover {
  background: transparent;
}
.card-button__wrapper:hover .card-button{
  color: #ed885c;
}
.card-button{
  color: #FFF;
font-size: 14px;
font-style: normal;
font-weight: 700;
text-transform: uppercase;
cursor: pointer;
transition: all 0.3s ease-in-out;
}
</style>
<style>
.success-title {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
}
.card-title {
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 20px;
}

.param-title {
  color: #000;

  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 200;
}
.param-value {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-left: 10px;
}
.text-title {
  color: #000;

  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
}
.success-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 60px;
}

@media (max-width: 500px) {
  .card-title {
    font-size: 16px;
  }
  .card-add__title{
    font-size: 12px;
  }
  .success-title {
    font-size: 12px;
  }
  .success-wrapper {
    margin-bottom: 50px;
  }

}
</style>
<template>
  <div class="params-wrapper">
    <div class="params">
      <div class="amount param">
        <div class="param-title">Сумма:</div>
        <div class="param-value">{{ this.form.amount }} ₽</div>
      </div>
      <div class="period param">
        <div class="param-title">Срок:</div>
        <div class="param-value">{{ this.form.period }} дн.</div>
      </div>
      <div class="percent param">
        <div class="param-title">Ставка:</div>
        <div class="param-value">0%</div>
      </div>
    </div>
  </div>
  <div class="success-wrapper">
    <div class="success">
      <div class="success-title">Ваша заявка одобрена</div>
      <img src="/img/good.png" alt="" />
    </div>
  </div>
  <div class="card-title">Добавление карты</div>
  <div class="card-add__wrapper">
    <div class="card-add">
      <div class="card-add__title">
        Введите данные карты для идентификации. Мы спишем 1 руб и сразу вернем.
        Внимание! Карта не должна быть виртуальной, по карте должны совершаться
        транзакции
      </div>
      <div class="card-add__form">
        <q-input
          :rules="cardNumberValidate"
          rounded
          standout
          class="card-num"
          v-model="form.cardNumber"
          mask="#### #### #### ####"
          placeholder="Номер карты"
          ref="inputCardNum"
        >
        </q-input>
        <div class="card-add__form__data">
          <q-input
          :rules="cardDateValidate"
          rounded
          standout
          class="card-date"
          v-model="form.cardDate"
          mask="##/##"
          placeholder="Срок действия"
          ref="inputCardDate"
        >
        </q-input>
        <q-input
          :rules="cardCvvValidate"
          rounded
          standout
          class="card-cvv"
          v-model="form.card"
          mask="###"
          placeholder="CVV"
          ref="inputCardCvv"
        >
        </q-input>
        </div>
      </div>
      <div class="card-button__wrapper">
      <button class="card-button">
        добавить
      </button>
    </div>
    </div>
    
  </div>
</template>
<script>
export default {
  name: "Card",
  data() {
    return {
      form: {
        cardNumber: null,
        cardDate: null,
        cardCvv: null,
        amount: 0,
        period: 0,
      },
    };
  },
  computed: {
  
  },
  created() {
    this.form.amount = localStorage.getItem("amount");
    this.form.period = localStorage.getItem("period");
  },
};
</script>
