<style scoped>
/* .input-wrap-property {
  flex-direction: column;
}
.radio-custom{
  margin-left: 0 !important;
}*/

.radio-text {
  font-size: 14px !important;
}
.input-wrap {
  align-items: center;
}
input[type="date"]::placeholder {
  color: #999; /* Цвет плейсхолдера */
}
.q-avatar {
  width: 16px !important;
  top: -6px;
}

.phone-input .q-avatar {
  width: 14px !important;
}
.q-field--standout.q-field--highlighted .q-field__control {
  background: #f5f5f5 !important;
  box-shadow: none !important;
}
.q-field__label {
  color: #6d6d6d !important;
  font-weight: 400 !important;
  top: 11px !important;
}
.q-field--standout.q-field--highlighted .q-field__input {
  color: #6d6d6d !important;
}
::v-deep(.q-field--standout.q-field--highlighted .q-field__input) {
  text-align: center;
}

.q-select .q-field__input {
  font-size: 16px;
  transform: scale(0.7);
  transform-origin: left top;
  margin-top: 5px;
}
::v-deep(.q-select .q-field__input) {
  text-align: center;
  margin-left: 65px;
}
::v-deep(.q-field--standout.q-field--highlighted .q-field__native) {
  text-align: center;
}
::v-deep(.material-icons) {
  position: absolute;
  right: 5%;
  top: 20%;
}
::v-deep(.q-field__native) {
  text-align: center;
}

.material-icons {
  top: -5px;
}
::v-deep(.q-spinner) {
  position: absolute;
  right: 5%;
  margin-top: -10px;
}
.q-field--standout.q-field--highlighted .q-field__native {
  color: #6d6d6d !important;
}
.phone-input .q-field__label {
  top: 10px !important;
}
.q-checkbox__inner--truthy .q-checkbox__bg {
  background: #ed885c !important;
}
.q-checkbox__bg {
  border: 2px solid #ed885c !important;
}
.q-field--auto-height .q-field__native {
  min-height: 30px !important;
}
.q-field--with-bottom {
  margin-bottom: 5px !important;
}
.q-field__bottom--animated {
  bottom: -10px !important;
}
</style>
<style scoped>
.anketa-wrap {
  max-width: 300px;
  margin: 0 auto;
}
@media (max-width: 450px) {
  .anketa-wrap {
    padding: 0;
  }
}
/*slider*/

.min,
.max {
  display: none;
}
.text {
  color: #040404;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
.slider-ui {
  position: relative;
  width: 210px;
  height: 40px;
}
.slider-ui input {
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  width: 100%;
  cursor: pointer;
  opacity: 0;
}
.slider-ui .bar {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #000;
  border-radius: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slider-ui .min,
.slider-ui .max {
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  font-weight: 800;
  color: #fff;
}
.slider-ui .min {
  left: 2%;
}
.slider-ui .max {
  right: 2%;
}
.slider-ui .track {
  position: absolute;
  z-index: 3;
  left: 25px;
  right: 25px;
  top: 0;
  bottom: 0;
}
.slider-ui .value {
  position: absolute;
  left: 50%;
  top: 18%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 800;
  color: #fff;
  background-color: #ed885c;
  border: 4px solid #000;
  border-radius: 100%;
  box-sizing: border-box;
  transform: translateX(-50%);
  transition: top 0.3s ease-in-out, color 0.3s ease-in-out;
}
.slider-ui .value.up {
  top: -110%;
  color: #000;
}
.slider-ui.color1 .bar {
  background-color: #f5f5f5;
}
.slider-ui.color2 .bar {
  background-color: #f5f5f5;
}
.slider-ui.color1 .value {
  border-color: #ed885c;
  transition: all 0.3s ease-in-out;
}
.slider-ui.color2 .value {
  border-color: #ed885c;
  transition: all 0.3s ease-in-out;
}
.sum-wrapper,
.days-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.slider-display {
  background: #f5f5f5;
  border-radius: 56px;
  width: 80px;
  padding: 9px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.slider-value {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #000;
}
.slider-value-2 {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #000;
  padding-left: 15px;
}
.docs-wrapper {
  margin-top: 18px !important;
}
.currency {
  color: #767676;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
}
.info-wrapper {
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
}
.consent-text {
  width: 90%;
}
.form-button__second {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  border-radius: 10px;
  background: #ed885c;
  border: 1px solid #ed885c;
  padding: 10px 15px;
  text-align: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.form-button__second:hover {
  background: transparent;
  color: #ed885c;
}
.form-button__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.passport-wrapper {
  display: flex;
  justify-content: space-between;
}
.passport-wrapper q-input {
  width: 45%;
}
.q-field--standout.q-field--rounded .q-field__control {
  border: 1px solid transparent;
}
.q-field--error.q-field--standout.q-field--rounded .q-field__control {
  border: 1px solid #c10015;
}
.passport-wrapper .q-field {
  width: 48%;
}
.issue-date .q-field {
  width: 100% !important;
}
.continue-text {
  color: #000;
  font-size: 8px;
  font-style: normal;
  font-weight: 300;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-align: center;
  margin-top: 15px;
  cursor: pointer;
}
</style>
<style scoped>
.step-two__title {
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 50px;
  margin-bottom: 30px;
}
.title-step__two {
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;

  margin-bottom: 30px;
}
.anketa-wrap {
  padding: 40px 80px 30px 70px;
}
.label-issue {
  display: none;
}
.label-dob {
  display: none;
}
@media (max-width: 500px) {
  .label-issue,
  .label-dob {
    display: block;
  }
  .step-two__title {
    font-size: 16px;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .title-step__two {
    font-size: 16px;

    margin-bottom: 20px;
  }
  .anketa-wrap {
    padding: 20px 10px !important;
  }
}
.issue-date {
  position: relative;
  width: 48%;
}

.label-issue {
  position: absolute;
  top: 20%;
  left: 20%;
  transition: opacity 0.3s ease, transform 0.3s ease;
  z-index: 1;
  pointer-events: none; /* Чтобы label не блокировал клики на input */
}

.label-focused {
  opacity: 0;
  transform: translateY(-10px); /* Немного сдвигаем label, чтобы он ушел */
}

q-input {
  position: relative;
  z-index: 2; /* Убедимся, что input находится выше label */
}
.label-issue,
.label-dob {
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
}
.dob-date {
  position: relative;
  width: 100%;
}

.label-dob {
  position: absolute;
  top: 18%;
  left: 35%;
  transition: opacity 0.3s ease, transform 0.3s ease;
  z-index: 1;
  pointer-events: none; /* Чтобы label не блокировал клики на input */
}

.label-focused {
  opacity: 0;
  transform: translateY(-10px); /* Немного сдвигаем label, чтобы он ушел */
}

q-input {
  position: relative;
  z-index: 2; /* Убедимся, что input находится выше label */
}
.input-req input {
  text-align: center; /* Центрируем текст */
  padding: 0; /* Убираем лишние отступы */
  appearance: none; /* Сбрасываем нативный стиль */
  -webkit-appearance: none; /* Для Safari */
  -moz-appearance: none; /* Для Firefox */
}

.input-req {
  display: flex;
  align-items: center;
  justify-content: center; /* Центрируем текст */
}

.input-req input[type="date"]::-webkit-inner-spin-button,
.input-req input[type="date"]::-webkit-calendar-picker-indicator {
  display: none; /* Убираем стрелки и иконки, которые могут сбивать форматирование */
}

.input-req input[type="date"] {
  line-height: 1.5; /* Убедитесь, что значение не сжимается */
  text-align: center; /* Центрируем текст вручную */
}
</style>
<style>
.q-radio__bg path {
  color: #ed885c !important;
}
.error-message {
  color: #c10015;
  transition: all 0.3s ease-in-out;
  font-size: 12px;
}
</style>
<template>
  
  <div class="title-step__two">Личные данные</div>
  <div
    class="form-wrapper anketa-wrap"
    style="align-items: normal; background: #fff; border-radius: 20px"
  >
    <q-select
      :color="themeColor"
      :rules="regionValidate"
      rounded
      standout
      v-model="regionTmp"
      class="input-req zoom-input"
      use-input
      hide-selected
      fill-input
      @input-value="(val) => (form.region = val)"
      input-debounce="0"
      :options="regionSuggestOptions"
      @filter="fetchRegionSuggest"
      new-value-mode="add"
      placeholder="Регион"
      behavior="menu"
      ref="inputRegion"
    >
    </q-select>
    <q-select
      :color="themeColor"
      :rules="cityValidate"
      rounded
      standout
      v-model="cityTmp"
      class="input-req zoom-input"
      use-input
      hide-selected
      fill-input
      @input-value="(val) => (form.city = val)"
      input-debounce="0"
      :options="citySuggestOptions"
      @filter="fetchCitySuggest"
      new-value-mode="add"
      placeholder="Город"
      behavior="menu"
      ref="inputCity"
    >
    </q-select>

    <div class="dob-date">
      <q-input
        :color="themeColor"
        :rules="dobValidate"
        rounded
        standout
        class="input-req"
        v-model="form.dob"
        mask="##.##.####"
        placeholder="Дата рождения"
        ref="inputDob"
      />
    </div>

    <div class="passport-wrapper">
      <q-input
        rounded
        standout
        id="anketa-series"
        v-model="form.series"
        :rules="seriesValidate"
        ref="inputSeries"
        mask="####"
        placeholder="Серия паспорта"
        hide-dropdown-icon
      />
      <q-input
        rounded
        standout
        id="anketa-number"
        v-model="form.number"
        :rules="numberValidate"
        mask="######"
        ref="inputNumber"
        placeholder="Номер паспорта"
      />
    </div>
    <div class="passport-wrapper">
      <q-input
        rounded
        standout
        id="anketa-fmsCode"
        v-model="form.fmsCode"
        :rules="fmsCodeValidate"
        @focusout="getFmsUnit"
        ref="inputFmsCode"
        mask="###-###"
        placeholder="Код подразделения"
      />
      <div class="issue-date" style="position: relative; width: 48%">
        <q-input
          :color="themeColor"
          :rules="issueDateValidate"
          rounded
          standout
          class="input-req"
          v-model="form.issueDate"
          mask="##.##.####"
          placeholder="Дата выдачи"
          ref="inputIssueDate"
        />
      </div>
    </div>
    <div class="input-wrap properties-wrap">
      <div class="radio-text">У Вас есть автомобиль?</div>
      <div class="input-wrap-property">
        <q-radio v-model="form.auto" :val="1" label="Да" />
        <q-radio
          class="radio-custom"
          v-model="form.auto"
          :val="0"
          label="Нет"
        />
      </div>
      <q-slide-transition>
        <div v-if="error" class="error-message">
          <q-icon name="error" size="12px" class="q-mr-xs" />
          {{ error }}
        </div>
      </q-slide-transition>
    </div>
    <div class="form-button__wrapper">
      <button class="form-button__second" @click="next()">ПРОДОЛЖИТЬ</button>
    </div>

    <div @click="skip()" class="continue-text">Продолжить без заполнения</div>
  </div>
</template>

<script>
import * as Auth from "@/assets/js/auth.js";
import { debounce } from "debounce";
import vSelect from "@/../node_modules/vue-select/src/index.js";
import * as Bid from "@/assets/js/bid/default_create.js";
import * as ConfirmTel from "@/assets/js/confirm_tel.js";
import * as Suggest from "@/assets/js/suggest.js";
import * as Validate from "@/assets/js/validators.js";
import * as GetParams from "@/assets/js/get_params.js";
import * as DataParams from "@/assets/js/data_params.js";
import * as Goal from "@/assets/js/goal.js";

export default {
  name: "VamZaimStepTwo",

  components: { vSelect },

  props: {
    formType: String,
    forwardPage: Function,
    backwardPage: Function,
    previousPagePromise: Promise,
  },

  data: () => ({
    isDobFocused: false,
    isFocused: false,
    regionSuggestOptions: [],
    citySuggestOptions: [],
    regionTmp: null,
    cityTmp: null,
    sendPromise: Promise.resolve(),
    FioSuggestOptions: [],
    error: "",
    docs: false,
    consentTextDefault:
      '<a href="docs/Согласие_на_получение_информационой_и_рекламной_рассылки.pdf" target="_blank"> рекламной и информационной рассылок</a>',
    themeColor: DataParams.get("color"),
    isNextClicked: false,
    isPartialSubmitted: false,
    emailSuggestOptions: [],
    form: {
      fio: null,
      phone: null,
      email: null,
      consent: false,
      amount: 0,
      period: 0,
      region: null,
      city: null,
      cityKladr: null,
      regionKladr: null,
      fmsCode: null,
      fmsUnit: null,
      issueDate: null,
      bidId: null,
      auto: null,
    },
  }),
  created() {
    this.form.bidId = localStorage.getItem("bidId");
    if (!this.form.bidId) {
      window.location.href = "/";
    }
    this.form.amount = localStorage.getItem("amount");
    this.form.period = localStorage.getItem("period");
  },
  computed: {
    //validation
    seriesValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите серию паспорта",
        (val) => Validate.series(val) || "Введите полностью",
      ];
    },
    numberValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите номер паспорта",
        (val) => Validate.number(val) || "Введите полностью",
      ];
    },
    fmsCodeValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите полностью",
        (val) => Validate.fmsCode(val) || "Введите полностью",
        (val) => !!this.form.fmsUnit || "Введите корректно",
      ];
    },
    issueDateValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите полностью",
        (val) => Validate.date(val) || "Введите верную дату",
      ];
    },
    cityValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите город проживания",
        (val) => Validate.noempty(val) || "Введите полностью",
      ];
    },
    regionValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите регион проживания",
        (val) => Validate.noempty(val) || "Введите полностью",
      ];
    },
    dobValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите дату рождения",
        (val) => Validate.validate18Years(val) || "Введите верную дату",
      ];
      npm;
    },
    formatSum() {
      return this.form.amount.toLocaleString("ru-RU");
    },
  },

  watch: {
    "form.auto"(newValue) {
      if (newValue !== null) {
        this.error = ""; // Убираем ошибку сразу после выбора
      }
    },
    regionTmp: function (val) {
      this.form.region = val?.value;
      this.form.regionKladr = val?.id;
    },
    cityTmp: function (val) {
      this.form.city = val?.value;
      this.form.cityKladr = val?.id;
    },
  },
  methods: {
    handleDobFocus() {
      this.isDobFocused = true;
    },
    handleDobBlur() {
      // Если поле пустое, label снова появляется
      this.isDobFocused = false;
    },
    handleFocus() {
      this.isFocused = true;
    },
    handleBlur() {
      // Если поле пустое, label снова появляется
      this.isFocused = false;
    },

    getFmsUnit() {
      Suggest.suggestFmsCode(this.form.fmsCode)
        .then((data) => {
          this.form.fmsUnit = data;
          this.$refs.inputFmsCode.validate();
          if (!data) {
          }
        })
        .catch(() => {
          this.form.fmsCode = null;
          this.$refs.inputFmsCode.validate();
        });
    },

        async next() {
      const isValidSeries = await this.$refs.inputSeries.validate();
      const isvalidNumber = await this.$refs.inputNumber.validate();
      const isvalidFmsCode = await this.$refs.inputFmsCode.validate();
      const isvalidIssueDate = await this.$refs.inputIssueDate.validate();
      const isValidDob = await this.$refs.inputDob.validate();
      const isValidRegion = await this.$refs.inputRegion.validate();
      const isValidCity = await this.$refs.inputCity.validate();

      // Валидация поля "Наличие авто"
      if (this.form.auto === null) {
        this.error = "Выберите один из вариантов";
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }

      // Если выбрано значение, очищаем ошибку
      this.error = "";

      if (
        !isValidSeries ||
        !isvalidNumber ||
        !isvalidFmsCode ||
        !isvalidIssueDate ||
        !isValidDob ||
        !isValidRegion ||
        !isValidCity
      ) {
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }
      // ym(100485516, "reachGoal", "step2_filled");
      Bid.send(this.form);
      this.forwardPage(
        Promise.resolve({ amount: this.form.amount, period: this.form.period })
      );
    },


    skip() {
      // ym(100485516,'reachGoal','step2_skipped')
       this.forwardPage(
        Promise.resolve({ amount: this.form.amount, period: this.form.period })
      );

    },
    //suggest

    fetchFio: debounce(function (search, update) {
      if (!search.length) {
        () => (this.lastnameSuggestOptions = [search]);
        return;
      }
      this.fetchFioSuggest(search, update, "fio");
    }, 500),
    fetchFioSuggest(search, update, type) {
      Suggest.suggestFioFull(search, type)
        .then((data) => {
          console.log(data);
          update(() => {
            this.FioSuggestOptions = data.map((el) => el.text);
          });
        })
        .catch(() => {
          update(() => {
            this.FioSuggestOptions = [search];
          });
        });
    },
    fetchRegionSuggest: debounce(function (search, update) {
      if (search.length < 2) {
        () => (this.regionSuggestOptions = []);
        return;
      }
      Suggest.suggestRegion(search)
        .then((data) => {
          update(() => {
            if (data.length)
              this.regionSuggestOptions = data.map((el) => ({
                value: el.text,
                label: el.text,
                id: el.id,
              }));
            else
              this.regionSuggestOptions = [
                { value: search, label: search, id: null },
              ];
          });
        })
        .catch(() => {
          update(() => {
            this.regionSuggestOptions = [
              { value: search, label: search, id: null },
            ];
          });
        });
    }, 500),

    //city
    fetchCitySuggest: debounce(function (search, update) {
      if (search.length < 2) {
        () => (this.citySuggestOptions = []);
        return;
      }
      Suggest.suggestCity(search, this.form.regionKladr)
        .then((data) => {
          update(() => {
            if (data.length)
              this.citySuggestOptions = data.map((el) => ({
                value: el.text,
                label: el.text,
                id: el.id,
              }));
            else
              this.citySuggestOptions = [
                { value: search, label: search, id: null },
              ];
          });
        })
        .catch(() => {
          update(() => {
            this.citySuggestOptions = [
              { value: search, label: search, id: null },
            ];
          });
        });
    }, 500),

    getConsentText() {
      return window.consent || this.consentTextDefault;
    },
    //suggestions
    //Email suggestions
    fetchEmailSuggest: debounce(function (search, update) {
      if (!search.length) {
        () => (this.emailSuggestOptions = []);
        return;
      }
      Suggest.suggestEmail(search)
        .then((data) => {
          update(() => {
            if (data.length)
              this.emailSuggestOptions = data.map((el) => el.text);
            else this.emailSuggestOptions = [search];
          });
        })
        .catch(() => {
          update(() => {
            this.emailSuggestOptions = [search];
          });
        });
    }, 500),
  },

  mounted() {
    //geo ip
    Suggest.getGeoFromIp().then((geo) => {
      if (geo.cityKladr === null) {
        return;
      }
      this.regionTmp = {
        value: geo.region,
        label: geo.region,
        id: geo.regionKladr,
      };
      this.cityTmp = { value: geo.city, label: geo.city, id: geo.cityKladr };
      this.regionSuggestOptions = [this.regionTmp];
      this.citySuggestOptions = [this.cityTmp];
    });
  },
};
</script>
