<template>
    <iframe height="440" width="100%" scrolling="no" :src="formUrl" style="border:none;"></iframe>
</template>

<script>
export default {
    name: 'SubscriptionImpaya',

    props: {
        renderForm: Function,
        renderError: Function,
        formUrl: String
    },

    mounted () {
        //redirect
        window.addEventListener('message', e => {
            if(e.data.type !== 'AFTER_PAY') {
                return;
            }

            var url = e.data.data.RedirectUrl;
            var urlParts = url.split('?', 2);
            var redirectUrl = urlParts[0] + '?' + (urlParts[1] || '');

            window.location.replace(redirectUrl);
        });
    }
}
</script>
