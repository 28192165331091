<style scoped>
.block-top__body{
  background: transparent;
}
::v-deep(.q-field__native) {
  text-align: center;
}

::v-deep(.q-select .q-field__input) {
  text-align: center;
}

::v-deep(.q-field--standout.q-field--highlighted .q-field__native) {
  text-align: center;
}

label {
  width: 220px;
}

::v-deep(.q-field__native) {
  font-size: 14px !important;
}
</style>
<style>
.unsub-btn {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  border-radius: 10px;
  background: #ed885c;
  border: 1px solid #ed885c;
  padding: 10px 15px;
  text-align: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  margin-top: 30px;
}

.unsub-btn:hover {
  background: transparent;
  color: #ed885c;
}
.card-num{
  padding: 0px 5px;
  background: #fff;
  border-radius: 5px;
}
.unsub-link{
  cursor: pointer;
  text-decoration: underline;
}
.unsub-link__modal{
  cursor: pointer;
  text-decoration: underline;
   margin-top: 30px;
}
.backward-link{
   cursor: pointer;
  text-decoration: underline;
  justify-content: center;
  display: flex;
}
.card-list{
  padding: 0px 10px;
}
.modal-title{
  color: #000;
font-size: 32px;
font-style: normal;
font-weight: 700;
text-transform: uppercase;
}
.modal-subtitle{
color: #000;
text-align: center;
font-size: 14px;
font-style: normal;
font-weight: 400;
text-transform: uppercase;
margin-top: 30px;
}
.modal-text__wrapper{
  display: flex;
  flex-direction: column;
}
.unsub-btn__wrapper{
  display: flex;
   flex-direction: column;
}
.no-subscriptions{
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
<template>
  <div v-if="subscriptions.length > 0">
    <div v-for="subscription in subscriptions" :key="subscription.id" class="subs-wrapper">
      <div v-if="loading" class="subs-list">Загрузка...</div>
      <div>Дата подписки {{ subscription.subscribed_at }}</div>
      <div class="card-list">
        Ваша карта <span class="card-num">{{ subscription.card }}</span>
        <a class="unsub-link" @click="confirm = true">Отписаться</a>

        <q-dialog v-model="confirm" persistent>
          <q-card>
            <q-card-section class="items-center modal-text__wrapper">
              <div class="modal-title">ВЫ УВЕРЕНЫ?</div>
              <div class="modal-subtitle">
                Вы потеряете возможность получать наиболее выгодные и свежие предложения по займам
              </div>
            </q-card-section>

            <q-card-actions class="unsub-btn__wrapper">
              <button class="unsub-btn" v-close-popup>Я ЕЩЕ ПОДУМАЮ</button>
              <a class="unsub-link__modal" @click="unsub(subscription.bid_id)">Все равно отписаться</a>
            </q-card-actions>
          </q-card>
        </q-dialog>
      </div>
    </div>
  </div>
  
  <div v-else class="no-subscriptions">
    <div>Вам одобрен займ у наших партнеров!</div>
    <div> Нажмите на кнопку, чтобы получить деньги.</div>
    <button class="unsub-btn" @click="openSite()">Получить деньги</button>
  </div>
  
  <a v-if="subscriptions.length > 0" class="backward-link" @click="backwardPage">Назад</a>
</template>

<script>
import * as Subscription from '@/assets/js/bid/subscription_iframe.js';
import { Notify } from 'quasar';

export default {
  name: "SubscriptionsList",

  props: {
    previousPagePromise: Promise,
    backwardPage: Function,
  },

  data: () => ({
    confirm: false,
    phone: "",
    subscriptions: [],
    loading: false,
  }),

  async created() {
    const data = await this.previousPagePromise;
    this.phone = data.phone;
    this.fetchSubscriptions();
  },

  methods: {  
    openSite() {
      window.open('https://zaym8.ru', '_blank');
    },
    async fetchSubscriptions() {
      this.loading = true;
      const result = await Subscription.subscriptions(this.phone);
      
      this.subscriptions = result.error ? [] : result.subscriptions;
      this.loading = false;
    },

    async unsub(id) {
      this.loading = true;
      const result = await Subscription.unscribe(id);
      
      if (result.response) {
        Notify.create({
          message: result.response,
          color: result.error === 'error' ? 'red' : 'green',
        });
      }
      
      this.confirm = false; // Закрываем модальное окно
      await this.fetchSubscriptions(); // Обновляем список подписок
      this.loading = false;
    },
    
    redirectToHome() {
      this.$router.push('/');
    }
  },
};
</script>