/*
 * Модуль для отправки данных формы на сервер
 */

import * as Sender from './../sender.js'
import * as GetParams from './../get_params.js'

var getEndpointUrl = function() {
    const bidId = GetParams.get('id') || GetParams.get('bidId') || GetParams.get('bid_id') || window.bid.id || localStorage.getItem('bidId');
    
    return process.env.VUE_APP_API_BASE_URL + '/api/bid/info?id=' + bidId;
};

var get = function() {
    return Sender.send(getEndpointUrl(), {});
};

export { get }
