/*
 * Модуль для вставки данных в форму
 */

import * as Sender from './../sender.js'
import * as GetParams from './../get_params.js'

var getEndpointUrl = function() {
    const bidId = GetParams.get('form_bid_id');

    return process.env.VUE_APP_API_BASE_URL + '/api/bid/info-for-form?id=' + bidId;
};

var get = function() {
    return Sender.send(getEndpointUrl(), {});
};

export { get }
