<style scoped>
.anketa-wrap {
    padding: 0px;
}

::v-deep(.q-field__native) {
    text-align: center;
}

::v-deep(.q-select .q-field__input) {
    text-align: center;
}

::v-deep(.q-field--standout.q-field--highlighted .q-field__native) {
    text-align: center;
}

::v-deep(.q-field--standout.q-field--highlighted .q-field__input) {
    text-align: center;
}

.params-wrapper {
    max-width: 600px;
    width: 100%;
    border-radius: 20px;
    background: white !important;
    padding: 23px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.params {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
}

.param {
    display: flex;
    align-items: center;
}


.success {
    border-radius: 15px;
    background: linear-gradient(90deg, #0ba360 0%, #3cba92 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 340px;
    width: 100%;
    padding: 15px 25px;
}

.success img {
    margin-left: 10px;
    margin-top: -3px;
}

.card-add__wrapper {
    border-radius: 30px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 30px 35px 30px;
    max-width: 450px;
    margin: 0 auto;
    width: 100%;
}

.card-add {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 300px;
}

.card-add__title {
    color: #000;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 200;
    line-height: 120%;
}

.card-add__form {
    margin-top: 20px;
    width: 100%;
    margin-bottom: 30px;
}

.card-add__form__data {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card-add__form__data .q-field {
    width: 48%;
}

.card-num {
    margin-bottom: 10px !important;
}

.card-button__wrapper {
    border-radius: 12px;
    background: #ED885C;
    border: 1px solid #ED885C;
    padding: 12px 24px;
    transition: all 0.3s ease-in-out;
}

.card-button__wrapper:hover {
    background: transparent;
}

.card-button__wrapper:hover .card-button {
    color: #ed885c;
}

.card-button {
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}
</style>
<style>
.success-title {
    color: #fff;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
}

.card-title {
    color: #000;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 20px;
}

.param-title {
    color: #000;

    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 200;
}

.param-value {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-left: 10px;
}

.text-title {
    color: #000;

    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
}

.success-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 60px;
}

@media (max-width: 500px) {
    .card-title {
        font-size: 16px !important;
    }

    .card-add__title {
        font-size: 14px !important;
    }

    .success-title {
        font-size: 14px !important;
    }

    .success-wrapper {
        margin-bottom: 50px;
    }

}
.centering-text{
    width: 100%;
    padding: 10px 35px;
    text-align: center;
}
.sub-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
</style>

<template>
    <div class="payment-wrapper centering-text">
        <div v-show="load">
            <div class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
        <div v-show="!load" class="sub-wrapper">
            <div class="params-wrapper">
                <div class="params">
                    <div class="amount param">
                        <div class="param-title">Сумма:</div>
                        <div class="param-value">{{ bid?.amount }} ₽</div>
                    </div>
                    <div class="period param">
                        <div class="param-title">Срок:</div>
                        <div class="param-value">{{ bid?.period }} дн.</div>
                    </div>
                    <div class="percent param">
                        <div class="param-title">Ставка:</div>
                        <div class="param-value">0%</div>
                    </div>
                </div>
            </div>
            <div class="success-wrapper">
                <div class="success">
                    <div class="success-title">Уважаемый(-ая) {{ bid?.firstname }} {{ bid?.secondname }}! <br>Ваша заявка
                        одобрена</div>
               
                </div>
            </div>
            <div v-if="errorText">{{ errorText }}</div>
            <component v-if="formUrl" :is="currentComponent" :renderForm="renderForm" :renderError="renderError"
                :redirectCallback="redirectCallback" :formUrl="formUrl" />
        </div>
    </div>
</template>

<script>
import * as Subscription from '@/assets/js/bid/subscription_iframe.js'
import * as Info from '@/assets/js/bid/info.js'
import SubscriptionPlatron from '@/components/SubscriptionPlatron.vue';
import SubscriptionImpaya from '@/components/SubscriptionImpaya.vue';
import SubscriptionBest2Pay from '@/components/SubscriptionBest2Pay.vue';

export default {
    name: 'Subscription',

    components: {
        platron: SubscriptionPlatron,
        impaya: SubscriptionImpaya,
        best2pay: SubscriptionBest2Pay
    },

    data: () => ({
        load: true,
        formUrl: null,
        errorText: null,
        currentComponent: null,
        bid: null,
    }),

    watch: {
        bid: {
            handler(val) {
                this.currentComponent = val?.paymentSystem
            },
            deep: true
        }
    },

    methods: {
        async redirectCallback(url) {
            const statusResponse = await Subscription.status(this.bid)
            
            if (statusResponse.error) {
                this.renderError(statusResponse.error);
                this.formUrl = null
                await this.$nextTick()
                this.formUrl = statusResponse.redirect
            } else if (statusResponse.redirect) {
                window.location.replace(statusResponse.redirect);
            } else {
                window.location.reload();
            }
        },
        renderForm() {
            //@TODO сделать перемотку к началу контейнера, а не страницы
            window.scrollTo(0, 0);

            this.load = false;
        },

        renderError(error) {
            this.load = false;
            this.errorText = error;
            console.warn(error);
        },
        async subscribe() {
            const subscriptionResponse = await Subscription.init(this.bid)

            if (subscriptionResponse.error) {
                this.renderError(subscriptionResponse.error);
                return
            }

            this.formUrl = subscriptionResponse.redirect

            this.renderForm();
        },
    },
    async mounted() {
        const infoResponse = await Info.get()

        if (infoResponse.error) {
            this.renderError(infoResponse.error);
            return
        }

        this.bid = infoResponse

        this.subscribe();
    },
}
</script>
