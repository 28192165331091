<style lang="scss" scoped>
.wrapper {
  min-height: 300px;
  justify-content: center;
  align-items: center;
}
progress-ring{
  .ring{
    stroke: black !important
  }
}
</style>

<template>
  <div class="row justify-center">
    <progress-ring percentage="100" int-size="26" radius="70" 
    colors='[[0,"#35C12C"]]' 
    easing-type="sineInOut" disable-decimals="true" duration="5000" round-linecap="true">
  </progress-ring>
  </div>
</template>

<script>
import * as ConfirmTel from "@/assets/js/confirm_tel.js";
import * as Auth from '@/assets/js/auth.js'
export default {
  name: "RedirectToCab",

  props: {
    formType: String,
    forwardPage: Function,
    backwardPage: Function,
    previousPagePromise: Promise,
  },

  mounted() {

  },
};
</script>
