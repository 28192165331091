<style>
.anketa-wrap{
  
  margin: 0 auto;
  
}
@media (max-width: 450px) {
   
}
    @import './assets/css/style.css';
</style>

<template>
    <div  class="anketa-wrap">

        <component
            :is="currentComponent"
            :formType="formType"
            :forwardPage="forwardPage"
            :backwardPage="backwardPage"
            :previousPagePromise="previousPagePromise"
        />
        
    </div>
</template>

<script>
    import * as Auth from '@/assets/js/auth.js'
    import * as DataParams from '@/assets/js/data_params.js'
    import * as GetParams from '@/assets/js/get_params.js'
    import FormDefault from '@/components/FormDefault.vue';
    import FormDefaultNew from '@/components/FormDefaultNew.vue';
    import FormDefaultNewTwoConsent from '@/components/FormDefaultNewTwoConsent.vue';
    import FormWeb from '@/components/FormWeb.vue';

    import FormDefaultStepOne from '@/components/FormDefaultStepOne.vue';
    import FormDefaultStepTwo from '@/components/FormDefaultStepTwo.vue';
    import FormDefaultNewStepOne from '@/components/FormDefaultNewStepOne.vue';
    import FormDefaultNewStepOneWithParams from '@/components/FormDefaultNewStepOneWithParams.vue';
    import FormDefaultNewStepTwo from '@/components/FormDefaultNewStepTwo.vue';
    import FormDefaultNewStepTwoWithParams from '@/components/FormDefaultNewStepTwoWithParams.vue';
    import FormDefaultNewStepThree from '@/components/FormDefaultNewStepThree.vue';
    import FormDefaultNewStepThreeWithParams from '@/components/FormDefaultNewStepThreeWithParams.vue';
    import FormDefaultNewRegistration from '@/components/FormDefaultNewRegistration.vue';
    import FormWithCalculator from '@/components/FormWithCalculator.vue';
    import ZaymstarStart from '@/components/ZaymstarStart.vue';
    import FormWithCalculatorStepTwo from '@/components/FormWithCalculatorStepTwo.vue';
    import Card from '@/components/Card.vue';
    import RedirectToCab from '@/components/RedirectToCab.vue';
    import UnsubForm from '@/components/UnsubForm.vue';
    import SubscriptionsList from '@/components/SubscriptionsList.vue';
    import VamZaimStepTwo from '@/components/VamZaimStepTwo.vue';
    import VamZaim from '@/components/VamZaim.vue';
    import VamZaimStart from '@/components/VamZaimStart.vue';
    
  
    
    import FormPts from '@/components/FormPts.vue';
    import FormBankruptcy from '@/components/FormBankruptcy.vue';
    import Thanks from '@/components/Thanks.vue';
    import Redirect from '@/components/Redirect.vue';
    import RedirectToCard from '@/components/RedirectToCard.vue';
    import RedirectToCardVamZaim from '@/components/RedirectToCardVamZaim.vue';
    import Subscription from '@/components/Subscription.vue';
    import SubscriptionVamZaim from '@/components/SubscriptionVamZaim.vue';
    import PhoneConfirmation from './components/PhoneConfirmation.vue';


    var formType = DataParams.get('type', 'default');
    var vuePages;

    switch (formType) {
        case 'default':
            vuePages = [FormDefault, Subscription];
            break;
        case 'confirm':
            vuePages = [ PhoneConfirmation];
            break;
        
        case 'default-free':
            vuePages = [FormDefault, Redirect];
            break;
        case 'default-free-new-confirmation':
            vuePages = [FormDefaultNewTwoConsent, PhoneConfirmation, Redirect];
            break;
        case 'default-free-new':
            vuePages = [FormDefaultNew, Redirect];
            break;
        case 'default-free-new-two-consent':
            vuePages = [FormDefaultNewTwoConsent, Redirect];
            break;
        case 'default-free-new-element':
            vuePages = [FormLongWeb, Thanks];
            break;
        case 'default-two-step':
            vuePages = [FormDefaultStepOne, FormDefaultStepTwo, Redirect];
            break;
        case 'default-new-three-step':
            vuePages = [FormDefaultNewStepOne, FormDefaultNewStepTwo, FormDefaultNewStepThree, RedirectToCab];
            break;
        case 'default-with-params-new-three-step':
            vuePages = [FormDefaultNewStepOneWithParams, FormDefaultNewStepTwoWithParams, FormDefaultNewStepThreeWithParams, RedirectToCab];
            break;
        case 'default-new-registration':
            vuePages = [FormDefaultNewRegistration,  RedirectToCab];
            break;
        case 'pts':
            vuePages = [FormPts, Thanks];
            break;
        case 'bankruptcy':
            vuePages = [FormBankruptcy, Thanks];
            break;
        case 'form-long-web':
            vuePages = [FormWeb, Thanks];
            break;
        case 'form-short-web':
            vuePages = [FormWeb, Thanks];
            break;
        case 'form-with-calculator':
        vuePages = [FormWithCalculator];
            break;
        case 'zaymstar-start':
            vuePages = [ZaymstarStart, RedirectToCard, Subscription];
            break;
        case 'form-with-calculator-step-two':
            vuePages = [FormWithCalculatorStepTwo, RedirectToCard, Subscription];
            break;
        case 'redirect-card':
            vuePages = [RedirectToCard, Card];
            break;
        case 'redirect-card-vamzaim':
            vuePages = [RedirectToCardVamZaim, Card];
            break;
        case 'redirect-to-cab':
            vuePages = [RedirectToCab];
            break;
        case 'card':
            vuePages = [Subscription];
            break;
        case 'unsub':
            vuePages = [UnsubForm, SubscriptionsList];
            break;
        case 'vamzaim':
            vuePages = [VamZaim];
            break;
        case 'vamzaim-step-two':
            vuePages = [VamZaimStepTwo, RedirectToCard, SubscriptionVamZaim];
            break;
        case 'vamzaim-start':
            vuePages = [VamZaimStart, RedirectToCard, SubscriptionVamZaim];
            break;
        case 'subslist':
            vuePages = [SubscriptionsList];
            break;
    }
    export default {
        components: {...vuePages},

        data: () => ({
            formType: formType,
            currentComponent: '0',
            previousPagePromise: Promise.resolve()
        }),

        async created() {
            Auth.setTokenFromQuery();
            if (Auth.getToken()) {
                Auth.load("/client/profile")
                    .then((data) => {
                        this.previousPagePromise = Promise.resolve(data);
                        this.currentComponent = '1';
                    })
                    .catch(() => {});
            }
        },

        methods: {
            //На следующую страницу
            forwardPage(promise) {
                if (typeof promise !== 'undefined') {
                    this.previousPagePromise = promise;
                }

                this.currentComponent = (++this.currentComponent).toString();
            },

            //На предыдущую страницу
            backwardPage() {
                this.currentComponent = (--this.currentComponent).toString();
            }
        },
        mounted(){
          
            
        }
    }
</script>
