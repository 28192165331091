<style scoped>
.fixed-full {
  background: rgba(24, 24, 27, 0.98) !important;
}
.q-card {
  padding: 20px 0px !important;
  border-radius: 30px !important;
}
.radio-text {
  font-size: 14px !important;
}

.input-wrap {
  align-items: center;
}
input[type="date"]::placeholder {
  color: #999; /* Цвет плейсхолдера */
}

.q-avatar {
  width: 16px !important;
  top: -6px;
}

.phone-input .q-avatar {
  width: 14px !important;
}
.q-field--standout.q-field--highlighted .q-field__control {
  background: #f5f5f5 !important;
  box-shadow: none !important;
}
.q-field__label {
  color: #6d6d6d !important;
  font-weight: 400 !important;
  top: 11px !important;
}
.q-field--standout.q-field--highlighted .q-field__input {
  color: #6d6d6d !important;
}

.q-select .q-field__input {
  font-size: 16px;
  transform: scale(0.7);
  transform-origin: left top;
  margin-top: 5px;
}

::v-deep(.material-icons) {
  position: absolute;
  right: 5%;
  top: 20%;
}

.material-icons {
  top: -5px;
}
::v-deep(.q-spinner) {
  position: absolute;
  right: 5%;
  margin-top: -10px;
}
.q-field--standout.q-field--highlighted .q-field__native {
  color: #6d6d6d !important;
}
.phone-input .q-field__label {
  top: 10px !important;
}
.q-checkbox__inner--truthy .q-checkbox__bg {
  background: #ed885c !important;
}
.q-checkbox__bg {
  border: 2px solid #ed885c !important;
}
.q-field--auto-height .q-field__native {
  min-height: 30px !important;
}
.q-field--with-bottom {
  margin-bottom: 5px !important;
}
.q-field__bottom--animated {
  bottom: -10px !important;
}
</style>
<style scoped>
/*slider*/

.min,
.max {
  display: none;
}
.text {
  color: #040404;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
.slider-ui {
  position: relative;
  width: 210px;
  height: 40px;
}
.slider-ui input {
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  width: 100%;
  cursor: pointer;
  opacity: 0;
}
.slider-ui .bar {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #000;
  border-radius: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slider-ui .min,
.slider-ui .max {
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  font-weight: 800;
  color: #fff;
}
.slider-ui .min {
  left: 2%;
}
.slider-ui .max {
  right: 2%;
}
.slider-ui .track {
  position: absolute;
  z-index: 3;
  left: 25px;
  right: 25px;
  top: 0;
  bottom: 0;
}
.slider-ui .value {
  position: absolute;
  left: 50%;
  top: 18%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 800;
  color: #fff;
  background-color: #ed885c;
  border: 4px solid #000;
  border-radius: 100%;
  box-sizing: border-box;
  transform: translateX(-50%);
  transition: top 0.3s ease-in-out, color 0.3s ease-in-out;
}
.slider-ui .value.up {
  top: -110%;
  color: #000;
}
.slider-ui.color1 .bar {
  background-color: #f5f5f5;
}
.slider-ui.color2 .bar {
  background-color: #f5f5f5;
}
.slider-ui.color1 .value {
  border-color: #ed885c;
  transition: all 0.3s ease-in-out;
}
.slider-ui.color2 .value {
  border-color: #ed885c;
  transition: all 0.3s ease-in-out;
}
.sum-wrapper,
.days-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.slider-display {
  background: #f5f5f5;
  border-radius: 56px;
  width: 80px;
  padding: 9px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.slider-value {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #000;
}
.slider-value-2 {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #000;
  padding-left: 15px;
}

.currency {
  color: #767676;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
}
.info-wrapper {
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
}
.consent-text {
  width: 90%;
}
.form-button__second {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  border-radius: 10px;
  background: #ed885c;
  border: 1px solid #ed885c;
  padding: 10px 15px;
  text-align: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.form-button__second:hover {
  background: transparent;
  color: #ed885c;
}
.form-button__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.passport-wrapper {
  display: flex;
  justify-content: space-between;
}
.passport-wrapper q-input {
  width: 45%;
}
.q-field--standout.q-field--rounded .q-field__control {
  border: 1px solid transparent;
}
.q-field--error.q-field--standout.q-field--rounded .q-field__control {
  border: 1px solid #c10015;
}
.passport-wrapper .q-field {
  width: 48%;
}
.issue-date .q-field {
  width: 100% !important;
}
.continue-text {
  color: #000;
  font-size: 8px;
  font-style: normal;
  font-weight: 300;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-align: center;
  margin-top: 15px;
  cursor: pointer;
}
</style>
<style scoped>
.step-two__title {
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 50px;
  margin-bottom: 30px;
}
.title-step__two {
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;

  margin-bottom: 30px;
}
.anketa-wrap {
  padding: 40px 80px 30px 70px;
}
.label-issue {
  display: none;
}
.label-dob {
  display: none;
}

.issue-date {
  position: relative;
  width: 48%;
}

.label-issue {
  position: absolute;
  top: 20%;
  left: 20%;
  transition: opacity 0.3s ease, transform 0.3s ease;
  z-index: 1;
  pointer-events: none; /* Чтобы label не блокировал клики на input */
}

.label-focused {
  opacity: 0;
  transform: translateY(-10px); /* Немного сдвигаем label, чтобы он ушел */
}

q-input {
  position: relative;
  z-index: 2; /* Убедимся, что input находится выше label */
}
.label-issue,
.label-dob {
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
}
.dob-date {
  position: relative;
  width: 100%;
}

.label-dob {
  position: absolute;
  top: 18%;
  left: 35%;
  transition: opacity 0.3s ease, transform 0.3s ease;
  z-index: 1;
  pointer-events: none; /* Чтобы label не блокировал клики на input */
}

.label-focused {
  opacity: 0;
  transform: translateY(-10px); /* Немного сдвигаем label, чтобы он ушел */
}

q-input {
  position: relative;
  z-index: 2; /* Убедимся, что input находится выше label */
}
.input-req input {
  text-align: center; /* Центрируем текст */
  padding: 0; /* Убираем лишние отступы */
  appearance: none; /* Сбрасываем нативный стиль */
  -webkit-appearance: none; /* Для Safari */
  -moz-appearance: none; /* Для Firefox */
}

.input-req {
  display: flex;
  align-items: center;
  justify-content: center; /* Центрируем текст */
}

.input-req input[type="date"]::-webkit-inner-spin-button,
.input-req input[type="date"]::-webkit-calendar-picker-indicator {
  display: none; /* Убираем стрелки и иконки, которые могут сбивать форматирование */
}

.input-req input[type="date"] {
  line-height: 1.5; /* Убедитесь, что значение не сжимается */
  text-align: center; /* Центрируем текст вручную */
}
</style>
<style>
.q-radio__bg path {
  color: #ed885c !important;
}
.error-message {
  color: #c10015;
  transition: all 0.3s ease-in-out;
  font-size: 12px;
}
@media (max-width: 500px) {
  .form-button__second {
    width: 60% !important;
  }
  .label-issue,
  .label-dob {
    display: block !important;
  }
  .step-two__title {
    font-size: 16px;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .title-step__two {
    font-size: 16px;

    margin-bottom: 20px;
  }
  .anketa-wrap {
    padding: 20px 10px !important;
  }
}
</style>













<style>
a {
  cursor: pointer;
}
.q-avatar {
  width: 16px !important;
  top: -6px;
}

.phone-input .q-avatar {
  width: 14px !important;
}
.q-field--standout.q-field--highlighted .q-field__control {
  background: #f5f5f5 !important;
  box-shadow: none !important;
}
.phone .q-field__native {
  font-size: 16px;
  transform: scale(0.75);
  transform-origin: left top;
  margin-top: 8px;
}
.q-field__label {
  color: #6d6d6d !important;
  font-weight: 400 !important;
  top: 11px !important;
}
.q-field--standout.q-field--highlighted .q-field__input {
  color: #6d6d6d !important;
}
.q-select .q-field__input {
  font-size: 16px;
  transform: scale(0.75);
  transform-origin: left top;
  margin-top: 5px;
}
.q-field--auto-height .q-field__control {
  height: 40px !important;
  min-height: 40px !important;
}
.q-field--standout.q-field--rounded .q-field__control {
  height: 40px !important;
  min-height: 40px !important;
}
.material-icons {
  font-size: 0px !important;
}
.q-spinner {
  margin-top: -10px;
}
.q-field--standout.q-field--highlighted .q-field__native {
  color: #6d6d6d !important;
}
.phone-input .q-field__label {
  top: 10px !important;
}
.q-checkbox__inner--truthy .q-checkbox__bg {
  background: #ed885c !important;
}
.q-checkbox__bg {
  border: 2px solid #ed885c !important;
}
.q-field--auto-height .q-field__native {
  min-height: 30px !important;
}
.q-field--with-bottom {
  margin-bottom: 5px !important;
}
.q-field__bottom--animated {
  bottom: -10px !important;
}
.q-checkbox__bg {
  top: -50% !important;
}
@media (max-width: 768px) {
  .q-checkbox__bg {
    top: 0% !important;
  }
}
@media (max-width: 441px) {
  .q-checkbox__bg {
    top: -30% !important;
  }
}
@media (max-width: 400px) {
  .q-checkbox__bg {
    top: -50% !important;
  }
}
</style>
<style scoped>
.text-primary {
  color: #ed885c !important;
}
.anketa-wrap {
  margin: 0 auto;
  padding: 0;
}
@media (max-width: 450px) {
  .anketa-wrap {
    padding: 0;
  }
}
/*slider*/

.min,
.max {
  display: none;
}
.text {
  color: #040404;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
.slider-ui {
  position: relative;
  width: 210px;
  height: 40px;
}
.slider-ui input {
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  width: 100%;
  cursor: pointer;
  opacity: 0;
}
.slider-ui .bar {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #000;
  border-radius: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slider-ui .min,
.slider-ui .max {
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  font-weight: 800;
  color: #fff;
}
.slider-ui .min {
  left: 2%;
}
.slider-ui .max {
  right: 2%;
}
.slider-ui .track {
  position: absolute;
  z-index: 3;
  left: 25px;
  right: 25px;
  top: 0;
  bottom: 0;
}
.slider-ui .value {
  position: absolute;
  left: 50%;
  top: 18%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 800;
  color: #fff;
  background-color: #ed885c;
  border: 4px solid #000;
  border-radius: 100%;
  box-sizing: border-box;
  transform: translateX(-50%);
  transition: top 0.3s ease-in-out, color 0.3s ease-in-out;
}
.slider-ui .value.up {
  top: -110%;
  color: #000;
}
.slider-ui.color1 .bar {
  background-color: #f5f5f5;
}
.slider-ui.color2 .bar {
  background-color: #f5f5f5;
}
.slider-ui.color1 .value {
  border-color: #ed885c;
  transition: all 0.3s ease-in-out;
}
.slider-ui.color2 .value {
  border-color: #ed885c;
  transition: all 0.3s ease-in-out;
}
.sum-wrapper,
.days-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
}
.slider-display {
  background: #f5f5f5;
  border-radius: 56px;
  width: 80px;
  padding: 9px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.slider-value {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #000;
}
.slider-value-2 {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #000;
  padding-left: 15px;
}
.docs-wrapper {
  margin-top: 18px !important;
}
.currency {
  color: #767676;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
}
.info-wrapper {
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
}

.info-wrapper img {
  margin-top: 5px;
}
.consent-text {
  width: 90%;
}
.form-button {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  border-radius: 10px;
  background: #ed885c;
  border: 1px solid #ed885c;
  padding: 10px 15px;
  text-align: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.form-button:hover {
  background: transparent;
  color: #ed885c;
}
.anketa-wrap {
  margin: 0 auto;
  padding: 0 !important;
}
::v-deep(.q-checkbox__bg) {
  top: 25% !important;
}
::v-deep(.docs-wrapper) {
  margin-top: 0 !important;
}
::v-deep(.form-button__second) {
  width: 40%;
  font-size: 13px !important;
  padding: 12px 24px;
}
::v-deep(.form-button__wrapper) {
  margin-bottom: 15px;
}

.calculator-wrapper {
  align-items: normal;
  background: #fff;
  border-radius: 20px;
  width: 100%;
  padding: 20px 80px;
}
</style>
<style lang="scss">
.second-step .q-select .q-field__input {
  text-align: center !important;
  margin-left: 65px !important;
}
.second-step {
  .q-field__native {
    text-align: center !important;
  }
}
.second-step {
  .q-field__control-container {
    font-size: 12px !important;
  }
}
.second-step .q-field--standout.q-field--highlighted .q-field__input {
  text-align: center !important;
}
.second-step .q-field--standout.q-field--highlighted .q-field__native {
  text-align: center !important;
}
.form-wrapper {
  width: 100%;
}
.q-dialog__inner > .q-card > .q-card__actions .q-btn--rectangle {
  background: #ed885c !important;
  border: 1px solid #ed885c !important;
  color: #fff !important;
  padding: 10px 15px !important;
  text-align: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  border-radius: 10px !important;
  font-size: 12px !important;
  font-weight: 700 !important;
}
.q-dialog__inner > .q-card > .q-card__actions .q-btn--rectangle:hover {
  background: transparent;
  border: 1px solid #ed885c !important;
}
.q-select .q-field__input {
  min-width: 250px !important;
}
@media (max-width: 768px) {
  .info-wrapper {
    justify-content: normal;
  }
  .info-text {
    margin-left: 12px;
  }
  .second-step .q-select .q-field__input {
    margin-left: 50px !important;
  }
}
</style>
<template>
  <div class="main-block__text">Займы под 0% до 100 000 руб.</div>
  <div class="calculator-wrapper">
    <SliderComponent
      v-model="form.amount"
      label="Сумма"
      :min="10000"
      :max="60000"
      :step="5000"
      unit="₽"
    />
    <SliderComponent
      v-model="form.period"
      label="Срок"
      :min="10"
      :max="60"
      :step="5"
      unit="дн."
    />

    <div class="form-wrapper">
      <q-select
        :color="themeColor"
        :rules="fioValidate"
        v-model="form.fio"
        use-input
        rounded
        standout
        hide-selected
        fill-input
        input-debounce="0"
        placeholder="ФИО"
        class="input-req"
        :options="FioSuggestOptions"
        @filter="fetchFio"
        ref="inputFio"
        new-value-mode="add"
        behavior="menu"
      >
        <template v-slot:prepend>
          <q-avatar>
            <img src="img/fio.svg" />
          </q-avatar>
        </template>
      </q-select>
      <q-input
        :rules="phoneValidate"
        rounded
        standout
        ref="inputPhone"
        v-model="form.phone"
        class="input-req input phone"
        @focus="handleFocus"
        @click="handleFocus"
        placeholder="Телефон"
        mask="+7 (###) ###-####"
      >
        <template v-slot:prepend>
          <q-avatar>
            <img src="img/phone.svg" />
          </q-avatar>
        </template>
      </q-input>

      <q-select
        :color="themeColor"
        :rules="emailValidate"
        :model-value="form.email"
        use-input
        hide-selected
        @input-value="(val) => (form.email = val)"
        fill-input
        rounded
        class="input-req"
        ref="inputEmail"
        aria-expanded="true"
        standout
        :options="emailSuggestOptions"
        @filter="fetchEmailSuggest"
        new-value-mode="add"
        placeholder="Email"
        behavior="menu"
      >
        <template v-slot:prepend>
          <q-avatar>
            <img src="img/email.svg" />
          </q-avatar>
        </template>
      </q-select>
      <div class="docs-wrapper">
        <q-checkbox
          ref="consentCheckbox"
          @click="removeClass"
          v-model="form.consent"
        />
        <div class="consent-text" ref="consentText">
          Я ознакомлен и согласен со
          <a color="primary" @click="alert = true">следующим</a>
          <q-dialog v-model="alert">
            <q-card>
              <q-card-section class="q-pt-none">
                Мне исполнилось 18 лет. Я подтверждаю ознакомление с
                <a target="_blank" href="docs/Политика конфиденциальности.pdf"
                  >политикой конфиденциальности</a
                >
                и даю своё
                <a
                  target="_blank"
                  href="docs/Согласие_на_обработку_персональных_данных.pdf"
                  >согласие на обработку персональных данных</a
                >,
                <a
                  target="_blank"
                  href="docs/Согласие_на_обработку_номера_телефона_мобильными_операторами.pdf"
                  >согласие на обработку номера телефона мобильными
                  операторами</a
                >,
                <a
                  target="_blank"
                  href="docs/Согласие_на_иформационную_и_рекламную_рассылку.pdf"
                  >согласие на информационную и рекламную рассылки</a
                >. <br> <br>
                 Я ознакомлен и соглашаюсь с
                <a
                  target="_blank"
                  href="docs/Тарифы сервиса.pdf"
                  >тарифами сервиса подбора займов </a
                > и с  <a
                  target="_blank"
                  href="docs/Соглашение_о_реккурентных_платежах.pdf"
                  > рекуррентными
                  платежами</a
                >.
              </q-card-section>

              <q-card-actions align="right">
                <q-btn flat label="Ознакомлен" v-close-popup />
              </q-card-actions>
            </q-card>
          </q-dialog>
        </div>
      </div>
    </div>
  </div>

  <div class="calculator-wrapper second-step">
    <q-select
      :color="themeColor"
      :rules="regionValidate"
      rounded
      standout
      v-model="regionTmp"
      class="input-req zoom-input"
      use-input
      hide-selected
      fill-input
      @input-value="(val) => (form.region = val)"
      input-debounce="0"
      :options="regionSuggestOptions"
      @filter="fetchRegionSuggest"
      new-value-mode="add"
      placeholder="Регион"
      behavior="menu"
      ref="inputRegion"
    >
    </q-select>
    <q-select
      :color="themeColor"
      :rules="cityValidate"
      rounded
      standout
      v-model="cityTmp"
      class="input-req zoom-input"
      use-input
      hide-selected
      fill-input
      @input-value="(val) => (form.city = val)"
      input-debounce="0"
      :options="citySuggestOptions"
      @filter="fetchCitySuggest"
      new-value-mode="add"
      placeholder="Город"
      behavior="menu"
      ref="inputCity"
    >
    </q-select>

    <div class="dob-date">
      <q-input
        :color="themeColor"
        :rules="dobValidate"
        rounded
        standout
        class="input-req"
        v-model="form.dob"
        mask="##.##.####"
        placeholder="Дата рождения"
        ref="inputDob"
      />
    </div>

    <div class="passport-wrapper">
      <q-input
        rounded
        standout
        id="anketa-series"
        v-model="form.series"
        :rules="seriesValidate"
        ref="inputSeries"
        mask="####"
        placeholder="Серия паспорта"
        hide-dropdown-icon
      />
      <q-input
        rounded
        standout
        id="anketa-number"
        v-model="form.number"
        :rules="numberValidate"
        mask="######"
        ref="inputNumber"
        placeholder="Номер паспорта"
      />
    </div>
    <div class="passport-wrapper">
      <q-input
        rounded
        standout
        id="anketa-fmsCode"
        v-model="form.fmsCode"
        :rules="fmsCodeValidate"
        @focusout="getFmsUnit"
        ref="inputFmsCode"
        mask="###-###"
        placeholder="Код подразделения"
      />
      <div class="issue-date" style="position: relative; width: 48%">
        <q-input
          :color="themeColor"
          :rules="issueDateValidate"
          rounded
          standout
          class="input-req"
          v-model="form.issueDate"
          mask="##.##.####"
          placeholder="Дата выдачи"
          ref="inputIssueDate"
        />
      </div>
    </div>
    <div class="input-wrap properties-wrap">
      <div class="radio-text">У Вас есть автомобиль?</div>
      <div class="input-wrap-property">
        <q-radio v-model="form.auto" :val="1" label="Да" />
        <q-radio
          class="radio-custom"
          v-model="form.auto"
          :val="0"
          label="Нет"
        />
      </div>
      <q-slide-transition>
        <div v-if="error" class="error-message">
          <q-icon name="error" size="12px" class="q-mr-xs" />
          {{ error }}
        </div>
      </q-slide-transition>
    </div>
    <div class="form-button__wrapper">
      <button class="form-button__second" @click="next()">ОТПРАВИТЬ</button>
    </div>
    <div @click="next()" class="continue-text">Продолжить без заполнения</div>
    <div class="info-wrapper">
      <img src="img/rkn-ico.png" alt="" />
      <div class="consent-text info-text">
        Ваши данные надежно защищены, являемся оператором персональных данных №
        23-20-010692. Приказ № 131 от 30.04.2020.
      </div>
    </div>
  </div>
</template>

<script>
import * as Auth from "@/assets/js/auth.js";
import { debounce } from "debounce";
import vSelect from "@/../node_modules/vue-select/src/index.js";
import * as Bid from "@/assets/js/bid/default_create.js";
import * as ConfirmTel from "@/assets/js/confirm_tel.js";
import * as Suggest from "@/assets/js/suggest.js";
import * as Validate from "@/assets/js/validators.js";
import * as GetParams from "@/assets/js/get_params.js";
import * as DataParams from "@/assets/js/data_params.js";
import * as FormBidId from "@/assets/js/bid/form_bid_id";
import * as Goal from "@/assets/js/goal.js";
import SliderComponent from "./SliderComponent.vue";

export default {
  name: "ZaymstarStart",

  components: { vSelect, SliderComponent },

  props: {
    formType: String,
    forwardPage: Function,
    backwardPage: Function,
    previousPagePromise: Promise,
  },

  data: () => ({
    alert: false,
    FioSuggestOptions: [],
    error: null,
    docs: false,
    isFocused: false,
    consentTextDefault:
      '<a href="docs/Согласие_на_получение_информационой_и_рекламной_рассылки.pdf" target="_blank"> рекламной и информационной рассылок</a>',
    themeColor: DataParams.get("color"),
    isNextClicked: false,
    isPartialSubmitted: false,
    emailSuggestOptions: [],
    firstnameSuggestOptions: [],
    lastnameSuggestOptions: [],
    secondnameSuggestOptions: [],
    regionSuggestOptions: [],
    citySuggestOptions: [],
    regionTmp: null,
    cityTmp: null,
    form: {
      fio: null,
      phone: null,
      email: null,
      consent: false,
      amount: 15000,
      period: 30,
      bidId: null,
      region: null,
      city: null,
      cityKladr: null,
      regionKladr: null,
      fmsCode: null,
      fmsUnit: null,
      issueDate: null,
      auto: null,

    },
    valid: {
      fio: true,
      phone: true,
      email: true,
      consent: true,
    },
  }),
  created() {
    localStorage.removeItem("bidId");
  },
  computed: {
    //validation
    phoneValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите телефон",
        (val) => Validate.phone(val) || "Введите полностью",
      ];
    },
    emailValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите email",
        (val) => Validate.email(val) || "Введите полностью",
      ];
    },
    fioValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите ФИО",
        (val) => Validate.textOnly(val) || "Введите корректно",
      ];
    },

    seriesValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите серию паспорта",
        (val) => Validate.series(val) || "Введите полностью",
      ];
    },
    numberValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите номер паспорта",
        (val) => Validate.number(val) || "Введите полностью",
      ];
    },
    fmsCodeValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите полностью",
        (val) => Validate.fmsCode(val) || "Введите полностью",
        (val) => !!this.form.fmsUnit || "Введите корректно",
      ];
    },
    issueDateValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите полностью",
        (val) => Validate.date(val) || "Введите верную дату",
      ];
    },
    cityValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите город проживания",
        (val) => Validate.noempty(val) || "Введите полностью",
      ];
    },
    regionValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите регион проживания",
        (val) => Validate.noempty(val) || "Введите полностью",
      ];
    },
    dobValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите дату рождения",
        (val) => Validate.validate18Years(val) || "Введите верную дату",
      ];
    },
    formatSum() {
      return this.form.amount.toLocaleString("ru-RU");
    },
  },

  watch: {
    regionTmp: function (val) {
      this.form.region = val?.value;
      this.form.regionKladr = val?.id;
    },
    cityTmp: function (val) {
      this.form.city = val?.value;
      this.form.cityKladr = val?.id;
    },
  },
  methods: {
    handleFocus() {
      if (!this.form.phone) {
        this.form.phone = "9"; // Устанавливаем 9, чтобы маска отобразилась
      }
      this.$nextTick(() => {
        const input = this.$refs.inputPhone?.$el?.querySelector("input");
        if (input) {
          requestAnimationFrame(() => {
            input.setSelectionRange(input.value.length, input.value.length);
          });
        }
      });
    },
    getFmsUnit() {
      Suggest.suggestFmsCode(this.form.fmsCode)
        .then((data) => {
          this.form.fmsUnit = data;
          this.$refs.inputFmsCode.validate();
          if (!data) {
          }
        })
        .catch(() => {
          this.form.fmsCode = null;
          this.$refs.inputFmsCode.validate();
        });
    },

    async next() {
      const isValidPhone = this.$refs.inputPhone.validate(); // Проверяем телефон
      const isValidEmail = this.$refs.inputEmail.validate(); // Проверяем email
      const isValidFio = this.$refs.inputFio.validate(); // Проверяем ФИО
      const consent = this.$refs.consentCheckbox.$el; // Проверяем согласие
      const consentText = this.$refs.consentText;

      // Если согласие не выбрано, показываем ошибку
       if (!this.form.consent) {
        consent.classList.add("is-invalid");
        consentText.classList.add("is-invalid-text");
        return true;
      }

      // Если одно из обязательных полей не валидно, не отправляем форму
      if (!isValidPhone || !isValidEmail || !isValidFio) {
        return true;
      }

      // Проверяем, заполнил ли пользователь все обязательные и необязательные поля
      if (this.isRequiredFilled() && this.isOptionalFilled()) {
        // Если заполнил все поля (обязательные + необязательные)
        ym(100485516, "reachGoal", "full_form_filled"); // Событие, если заполнил все поля
      } else if (this.isRequiredFilled()) {
        // Если заполнил только обязательные поля
        ym(100485516, "reachGoal", "required_only_filled"); // Событие, если заполнил только обязательные поля
      }

      // Отправляем форму
      Bid.send(this.form);
      // Переходим на следующий шаг
      this.forwardPage(
        Promise.resolve({ amount: this.form.amount, period: this.form.period })
      );
    },

    // Метод для проверки, заполнил ли пользователь обязательные поля
    isRequiredFilled() {
      return (
        this.$refs.inputPhone.isValid &&
        this.$refs.inputEmail.isValid &&
        this.$refs.inputFio.isValid
      );
    },

    // Метод для проверки, заполнил ли пользователь необязательные поля
    isOptionalFilled() {
      // Проверка, что хотя бы одно необязательное поле заполнено (например, дополнительное поле)
      return this.form.optionalField1 || this.form.optionalField2; // Замените на реальные поля
    },

    //suggest
    fetchRegionSuggest: debounce(function (search, update) {
      if (search.length < 2) {
        () => (this.regionSuggestOptions = []);
        return;
      }
      Suggest.suggestRegion(search)
        .then((data) => {
          update(() => {
            if (data.length)
              this.regionSuggestOptions = data.map((el) => ({
                value: el.text,
                label: el.text,
                id: el.id,
              }));
            else
              this.regionSuggestOptions = [
                { value: search, label: search, id: null },
              ];
          });
        })
        .catch(() => {
          update(() => {
            this.regionSuggestOptions = [
              { value: search, label: search, id: null },
            ];
          });
        });
    }, 500),

    //city
    fetchCitySuggest: debounce(function (search, update) {
      if (search.length < 2) {
        () => (this.citySuggestOptions = []);
        return;
      }
      Suggest.suggestCity(search, this.form.regionKladr)
        .then((data) => {
          update(() => {
            if (data.length)
              this.citySuggestOptions = data.map((el) => ({
                value: el.text,
                label: el.text,
                id: el.id,
              }));
            else
              this.citySuggestOptions = [
                { value: search, label: search, id: null },
              ];
          });
        })
        .catch(() => {
          update(() => {
            this.citySuggestOptions = [
              { value: search, label: search, id: null },
            ];
          });
        });
    }, 500),
    fetchFio: debounce(function (search, update) {
      if (!search.length) {
        () => (this.lastnameSuggestOptions = [search]);
        return;
      }
      this.fetchFioSuggest(search, update, "fio");
    }, 500),
    fetchFioSuggest(search, update, type) {
      Suggest.suggestFioFull(search, type)
        .then((data) => {
          update(() => {
            this.FioSuggestOptions = data.map((el) => el.text);
          });
        })
        .catch(() => {
          update(() => {
            this.FioSuggestOptions = [search];
          });
        });
    },

    getConsentText() {
      return window.consent;
    },

    //suggestions
    //Email suggestions
    fetchEmailSuggest: debounce(function (search, update) {
      if (!search.length) {
        () => (this.emailSuggestOptions = []);
        return;
      }
      Suggest.suggestEmail(search)
        .then((data) => {
          update(() => {
            if (data.length)
              this.emailSuggestOptions = data.map((el) => el.text);
            else this.emailSuggestOptions = [search];
          });
        })
        .catch(() => {
          update(() => {
            this.emailSuggestOptions = [search];
          });
        });
    }, 500),
  },
  watch: {
    "form.auto"(newValue) {
      if (newValue !== null) {
        this.error = ""; // Убираем ошибку сразу после выбора
      }
    },
    regionTmp: function (val) {
      this.form.region = val?.value;
      this.form.regionKladr = val?.id;
    },
    cityTmp: function (val) {
      this.form.city = val?.value;
      this.form.cityKladr = val?.id;
    },
  },
  mounted() {
    //geo ip
    Suggest.getGeoFromIp().then((geo) => {
      if (geo.cityKladr === null) {
        return;
      }
      this.regionTmp = {
        value: geo.region,
        label: geo.region,
        id: geo.regionKladr,
      };
      this.cityTmp = { value: geo.city, label: geo.city, id: geo.cityKladr };
      this.regionSuggestOptions = [this.regionTmp];
      this.citySuggestOptions = [this.cityTmp];
    });

    //parse form_bid_id
    FormBidId.get().then((bid) => {
      if (!bid.error) {
        this.form.fio = [bid?.lastname, bid?.firstname, bid?.secondname].filter(Boolean).join(' ') || null;
        this.form.email = bid?.email || null;
        this.form.phone = bid?.phone?.replace(/^7/, '') || null;
      }
    });
  },
};
</script>
