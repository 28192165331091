/*
 * Модуль для работы с iframe платежками
 */

var getEndpointUrl = function(bid) {
    return process.env.VUE_APP_API_BASE_URL + '/api/bid/subscription/' + bid.paymentSystem + '?id=' + bid.id;
};

var unscribeEndpointUrl = function(id) {
    return process.env.VUE_APP_API_BASE_URL + '/api/bid/unsubscribe?id=' + id;
};

var getStatusEndpointUrl = function(bid) {
    return process.env.VUE_APP_API_BASE_URL + '/api/' + bid.paymentSystem + '/last-payment-status?id=' + bid.id;
};

var subscriptionsEndpointUrl = function(phone) {
    return process.env.VUE_APP_API_BASE_URL + '/api/subscriptions/list?phone=' + phone;
};

var init = function(bid) {
    return fetch(getEndpointUrl(bid), {
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
    }).then(response => {
        return response.json();
    }).then(data => {
        return data;
    });
};

var status = function(bid) {
    return fetch(getStatusEndpointUrl(bid), {
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
    }).then(response => {
        return response.json();
    }).then(data => {
        return data;
    });
};

var subscriptions = function(phone) {
    return fetch(subscriptionsEndpointUrl(phone), {
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
    }).then(response => {
        return response.json();
    }).then(data => {
        return data;
    });
};

var unscribe = function(id) {
    return fetch(unscribeEndpointUrl(id), {
        method: 'POST',
        headers: {'Content-Type': 'application/json'}
    }).then(response => {
        return response.json();
    }).then(data => {
        return data;
    });
};

export { init, status, subscriptions, unscribe }
