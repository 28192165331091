<template>
    <iframe height="830" width="100%" scrolling="no" :src="formUrl" style="border:none;"></iframe>

    <div class="payment-form-footer" style="display:none;">
        <div class="payment-form-small">
            
        </div>
    </div>
</template>

<script>
export default {
    name: 'SubscriptionPlatron',

    props: {
        renderForm: Function,
        renderError: Function,
        formUrl: String
    },
}
</script>
