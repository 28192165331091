//Валидация email
var email = function(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

//Валидация телефона
var phone = function(phone) {
    const re = /^\+7 \(\d\d\d\) \d\d\d-\d\d\d\d$/;
    return re.test(String(phone));
}


//18 years validate

var validate18Years = function(dt){
    
    if(!date(dt)){
        return false;
    }
    let parts = [];
    let dd = null;
    let mm = null;
    let yyyy = null;
    if (/^\d\d.\d\d.\d\d\d\d$/.test(dt)) {
        parts = dt.split('.');
        dd = parseInt(parts[0]);
        mm = parseInt(parts[1]);
        yyyy = parseInt(parts[2]);
    } else if (/^\d\d\d\d-\d\d-\d\d$/.test(dt)) {
        parts = dt.split('-');
        dd = parseInt(parts[2]);
        mm = parseInt(parts[1]);
        yyyy = parseInt(parts[0]);
    }
    
    var today = new Date(),
    birthDate = new Date(`${mm}/${dd}/${yyyy}`),
    age = today.getFullYear() - birthDate.getFullYear(),
    m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age >= 18;
}



//Валидация даты
var date = function(date) {
    let parts = [];
    let dd = null;
    let mm = null;
    let yyyy = null;
    if (/^\d\d.\d\d.\d\d\d\d$/.test(date)) {
        parts = date.split('.');
        dd = parseInt(parts[0]);
        mm = parseInt(parts[1]);
        yyyy = parseInt(parts[2]);
    } else if (/^\d\d\d\d-\d\d-\d\d$/.test(date)) {
        parts = date.split('-');
        dd = parseInt(parts[2]);
        mm = parseInt(parts[1]);
        yyyy = parseInt(parts[0]);
    }
    

    if (dd <= 0 || dd > 31 || mm <= 0 || mm > 12) {
        return false;
    }
    
    if (yyyy < 1950 || yyyy > 2060) {
        return false;
    }

    
    return true;
}

//Валидация года
var year = function(year) {
    const re = /^\d\d\d\d$/;
    var yyyy = parseInt(year);

    if (!re.test(year)) {
        return false;
    }

    if (yyyy < 1900 || yyyy > 2100) {
        return false;
    }

    return true;
}

//Проверка на пустоту
var noempty = function(string) {
    return string !== '' && string !== null && string !== undefined;
}

//Валидация серии паспорта
var series = function(series) {
    const re = /^\d\d\d\d$/;
    return re.test(String(series));
}

//Валидация номера паспорта
var number = function(number) {
    const re = /^\d\d\d\d\d\d$/;
    return re.test(String(number));
}

//Валидация кода подразделения
var fmsCode = function(fmsCode) {
    const re = /^\d\d\d-\d\d\d$/;
    return re.test(String(fmsCode));
}
//только буквы в поле
var textOnly = function(text) {
    const re = /^[а-яА-ЯёЁ ]*$/; // Разрешаем только русские буквы и пробелы
    if (!re.test(text)) return false; // Проверяем допустимые символы

    const words = text.trim().split(/\s+/); // Разбиваем строку по пробелам
    return words.length === 3; // Проверяем, что ровно 3 слова
}


export { email, phone, validate18Years, textOnly, date, year, noempty, fmsCode, number, series }

